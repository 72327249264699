import get from 'lodash/get';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

import { useGtm } from '@gtm-support/vue2-gtm';
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';

import { getCurrentProfileAlias } from '@/stores/User/Profile/ProfileMapper';
import { composeUserTrackingData, getPageTrackingData } from '@/helpers/user/tracking/trackingData';
import { bookingDuration } from '@/helpers/booking/bookingHelpers';
import { mapVehiclesData } from '@/helpers/user/tracking/ecommerceEvents';
import { getUserAddressCountry } from '@/stores/User/UserMapper';
import {
  trackingId, userCompanyName, userCountry, userLocale,
} from '@/stores/User/UserData/UserDataMapper';
import { GTM_EVENTS } from '@/constants/gtm';
import { CONTENTS_GROUP } from '@/helpers/user/tracking/eventsMap.const';

export const useTrackingGTM = () => {
  const gtm = useGtm();
  const route = useRoute();

  const userTrackingId = computed(trackingId);
  const companyName = computed(userCompanyName);
  const currentProfileAlias = computed(getCurrentProfileAlias);
  const userAddressCountry = computed(getUserAddressCountry);
  const userLocaleLanguage = computed(userLocale);
  const userCountryData = computed(userCountry);

  const trackPageView = ({ locationData, routerData } = {}) => {
    const newRoute = routerData || route;
    const locationSource = isNil(locationData) ? window.location : locationData;
    const location = get(locationSource, 'href');
    const userTrackingData = composeUserTrackingData({
      trackingId: userTrackingId,
      userCompanyName: companyName,
      currentProfile: currentProfileAlias,
      country: userAddressCountry,
    });

    const pageArgs = getPageTrackingData({
      country: userCountryData,
      language: userLocaleLanguage,
      document,
      newRoute,
      location,
    });

    window.dataLayer?.push({
      page_data: null,
    });

    gtm.trackEvent({
      event: GTM_EVENTS.pageView,
      user_data: userTrackingData,
      page_data: pageArgs,
    });
  };

  const trackInteractionEvent = ({ eventName, eventData = {} }) => {
    window.dataLayer?.push({
      page_data: null,
      event_data: null,
    });
    const pageArgs = {
      ...pick(getPageTrackingData({
        newRoute: route,
        document,
      }), ['name', 'content_group']),
    };

    gtm.trackEvent({
      event: eventName,
      event_data: eventData,
      page_data: pageArgs,
    });
  };

  const trackModalView = ({ modalName }) => {
    const locationData = {
      href: `${get(window, 'location.href')}/${modalName}`,
    };
    const currentRoute = route;
    const routerData = {
      ...currentRoute,
      fullPath: `${get(currentRoute, 'fullPath')}/${modalName}`,
      name: `${get(currentRoute, 'name')}_${modalName}`,
    };
    trackPageView({ locationData, routerData });
  };

  const trackEcommerceEvent = ({ eventName, eventData }) => {
    window.dataLayer?.push({
      page_data: null,
      ecommerce: null,
    });

    const pageArgs = {
      ...pick(getPageTrackingData({
        newRoute: route,
        document,
      }), ['name']),
      content_group: CONTENTS_GROUP.bookingCreation,
    };

    gtm.trackEvent({
      event: eventName,
      ecommerce: eventData,
      page_data: pageArgs,
    });
  };

  const trackCartEvent = ({
    bookingData, city, cityFallback, currency, paymentType, location, vehicles, discount, eventName,
  }) => {
    let bookingHours;
    try {
      const bookingStart = get(bookingData, 'start');
      const bookingEnd = get(bookingData, 'end');
      bookingHours = get(bookingDuration(bookingStart, bookingEnd), 'hours');
    } catch {
      bookingHours = 0;
    }

    const currentCity = get(city, 'name');
    const cityName = currentCity === 'unknown' || isNil(currentCity)
      ? get(cityFallback, 'location.city')
      : currentCity;

    const eventData = omitBy({
      currency,
      payment_type: paymentType,
      items: mapVehiclesData({
        city: cityName,
        location,
        vehicles,
        bookingHours,
        discount,
      }),
    }, isNil);

    const pageArgs = {
      ...pick(getPageTrackingData({
        newRoute: route,
        document,
      }), ['name']),
      content_group: CONTENTS_GROUP.bookingCreation,
    };

    window.dataLayer?.push({
      page_data: null,
      ecommerce: null,
    });

    gtm.trackEvent({
      event: eventName,
      ecommerce: eventData,
      page_data: pageArgs,
    });
  };

  return {
    trackPageView,
    trackModalView,
    trackEcommerceEvent,
    trackCartEvent,
    trackInteractionEvent,
  };
};
